<template>
  <div class="row justify-content-center">
    <div class="col-lg-10 col-xl-12">
      <BaseLoader v-if="loading" />
      <router-view v-else :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import BaseLoader from "../../components/common/BaseLoader";
import { mapGetters } from "vuex";
export default {
  name: "TicketPage",
  components: { BaseLoader },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters("auth", ["userId"])
  },
  created() {
    Promise.all([
      this.$store.dispatch("getCountries"),
      this.$store.dispatch("assistance/getAllOperators", { country_id: null }),
      this.$store.dispatch("assistance/getApiList"),
      this.$store.dispatch("assistance/getSavedMessageList", {
        users_id: this.userId
      })
    ]).then(() => {
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
